@import "tailwindcss/base";
@import "tailwindcss/components";
@import "perfect-scrollbar/css/perfect-scrollbar.css";
@import "tailwindcss/utilities";
/* @tailwind base;
@tailwind components;
@tailwind utilities; */


.dark .ps__rail-y,
.dark .ps__rail-x {
    background-color: transparent !important;
}
